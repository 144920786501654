@font-face {
  font-family: "Raylig";
  src: url("../../public/fonts/RayligAlternatelight-PKd9x.otf");
}
@font-face {
  font-family: "Raylig_semibold";
  src: url("../../public/fonts/RayligMedium-0Wq0R.otf");
}
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  list-style: none;
  text-decoration: none;
  font-family: "Roboto";
  border: none;
  user-select: none;
  outline: none;
}
.main-header {
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0;
  transition: all 0.4s ease-in-out;
  width: 100%;
  z-index: 9999;
  /* background: red; */
}
::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
a {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-bottom: 0;
}
:root {
  --bg: #1d1d1d;
  --color: #141414;
  --color1: #d7b8a6;
  --color2: #dddddd;
  --color3: #989898;
  --color4: #2b2b2b;
  --time: 0.4s;
  --famliy: "Raylig";
  --width: 1440px;
}
#max {
  display: block;
  max-width: 1920px;
  margin: auto;
  overflow-x: hidden;
  /* background: rgb(127, 160, 95); */
}

body {
  background: var(--bg);
}
.threejs {
  position: absolute;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -290px;
  transition: ease-in-out;
}
.slidersTexts {
  padding: 5px;
  white-space: wrap;
  /* overflow: hidden; */
}
.threejs > div {
  overflow: unset !important  ;
}
.itemHome {
  display: block;
  margin: auto;
}
.pad {
  padding: 0 calc((1920px - var(--width)) / 2);
}

/* header vs nav  */
.userNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color1);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  padding: 45px 0;
  position: relative;
  z-index: 99;
  transform: translateY(-200px);
  opacity: 0;
  animation: nav 0.8s linear forwards;
}
@keyframes nav {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.lang_btn {
  color: var(--color1);
  text-transform: uppercase;
  letter-spacing: 0.15em;
}
.scale {
  transform: scale(2);
}
.maxsulot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userNav a {
  color: var(--color1);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  display: flex;
  align-items: center;
  gap: 10px;
}
.maxsulot a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.detil {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  gap: 93px;
}
.detil li > button {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.headerUser {
  text-align: center;
}
.animate_text {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.animate_text > h2 {
  font-family: var(--famliy);
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: var(--color1);
  transform: translateY(100px);
  opacity: 0;
  animation: text 0.6s ease-in-out forwards;
}
@keyframes text {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.animate_text > p {
  font-family: var(--famliy);
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: var(--color1);
  transform: translateY(100px);
  opacity: 0;
  animation: text 0.6s ease-in-out forwards 0.4s;
}
.headerUser > h1 {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 1.075em;
  text-transform: uppercase;
  color: var(--color1);
  font-family: var(--famliy);
}
.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.imgs_bottom {
  background: transparent;
  cursor: pointer;
  /* transform: scale(1.5); */
  width: unset !important;
  height: unset !important;
}
.imgs_bottom img {
  animation: top_animate 1s linear infinite alternate-reverse;
}
.imgs_bottom:hover img {
  animation: animate-shadow 1.8s linear infinite;
}
@keyframes top_animate {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
.load {
  width: 100%;
  height: 600px;
  top: 0;
  left: 0;
  z-index: 99;
  background: #110a06c1;
  display: none;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  opacity: 0;
  z-index: -99999;
  transition: all 0.4s ease-in-out;
  flex-direction: column;
}
#maxsulotHaqida {
  position: relative;
}
.load > h1 {
  text-transform: uppercase;
  font-weight: 600;
  color: #d7b8a6;
}

.load.activ {
  display: flex;
  opacity: 1;
  z-index: 99999;
}
/* animatsiya */
.animate_header {
  height: 890px;
  position: relative;
  margin-top: -80px;
}
.animate_header .animate__ {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.animate_header .animate__:nth-child(1) img {
  animation: doira1 0.98s linear infinite;
}
.animate_header .animate__:nth-child(2) img {
  animation: doira 0.98s linear infinite;
}
@keyframes doira1 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes doira {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* contact */
.contact {
  margin: 380px 0 260px 0;
  background: var(--color);
  position: relative;
}
.contactBox {
  position: relative;
}
.contactBox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contact .contactBox:nth-child(1) {
  padding: 120px 182px 140px 0;
  text-align: right;
}

.contact .contactBox > h1 {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  text-align: right;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
  margin-bottom: 72px;
  font-family: var(--famliy);
}
.contact .contactBox > h1 span {
  font-size: 16px;
  /* font-family: 'Raylig_semibold'; */
  font-weight: 300;
  font-family: "Raylig_semibold";
}
.imgContact {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(20, 10, 4, 0.4);
}

.btn_ {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.15em;
  color: var(--color);
  padding: 10px 25px;
  background: var(--color1);
  border: 1px solid var(--color1);
  transition: all var(--time) ease-in-out;
  cursor: pointer;
}
.btn_:hover {
  background: transparent;
  color: var(--color1);
}
.circle {
  position: absolute;
  top: -180px;
  z-index: -1;
  left: -80px;
}
.contactUs {
  background: url("../../public/img/contactUs.png") center center/cover
    no-repeat;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.contactUs .imgContact {
  background: rgba(20, 20, 20, 0.8);
}
.contactusItem {
  position: relative;
  z-index: 1;
}
.contactusItem > h1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.3em;
  color: var(--color1);
  padding: 109px 0 120px;
  font-family: var(--famliy);
}

/* grid 3 */
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
  align-items: center;
  justify-content: center;
  text-align: center;
}
.contactUs {
  margin-top: 384px;
  padding-bottom: 117px;
}

.contact_net {
  text-align: center;
}
.contact_net img {
  margin-bottom: 21px;
}
.contact_net p {
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
}
.contact_net p:nth-child(2) {
  font-weight: 100;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.15em;
  text-transform: lowercase;
  color: var(--color3);
}
.contact_net {
  background: rgba(255, 255, 255, 0);
  transition: all var(--time) ease-in-out;
  cursor: pointer;
  padding: 10px;
}
.contact_net:hover {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  transform: scale(1.2);
}
.contact_net img {
  transition: all var(--time) ease-in-out;
}
.contact_net:hover img {
  animation: card 0.5s linear 1 alternate-reverse 0.4s;
}
@keyframes card {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0);
  }
}
.contactUs p span {
  font-weight: 300;
  color: var(--color1);
}
.contactusItem > p {
  margin-top: 59px;
}
.manzil {
  font-weight: 100;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.15em;
  color: var(--color3);
}
.flexCoruss {
  display: flex;
  align-items: center;
  justify-content: left;
}
.manzil a {
  color: var(--color1);
}
.manzil a span {
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.15em;
  color: var(--color1);
}
.shape2 {
  position: relative;
}
.shape2 img {
  position: absolute;
  right: -200px;
}
.shape2 img:nth-child(1) {
  top: -500px;
  z-index: -1;
}
.shape2 img:nth-child(2) {
  top: -515px;
  right: -500px;
  z-index: -1;
}
/* ustara */
.slick-arrow {
  display: none !important;
}
.firstCorus .slick-dots {
  text-align: left;
  position: unset;
  margin-top: 150px;
  /* display: flex; */
  align-items: center;
}
.slick-dots p {
  padding: 10px;
}
.slick-dots .sliderItems {
  margin: 0 15px;
}
.slick-dots .sliderBox {
  width: 100%;
  height: 10px;
  transform: scaleY(0.5);
  transition: all 0.4s ease-in-out;
}
.slick-active .sliderBox {
  transform: scaleY(1);
}
.slick-dots ul {
  display: flex;
  align-items: center;
  transform: translateY(5px);
}
.ustaraCorusel {
  position: relative;
  margin-bottom: 380px;
}
.coruselDetil {
  position: absolute;
  top: -130px;
  left: 1481px;
}
.cardUstara {
  background: #232323;
  border: 0.1px solid transparent;
  padding: 16px 13px 17px;
  position: relative;
  margin: 0 20px;
  transition: all var(--time) ease-in-out;
  overflow: hidden;
  cursor: pointer;
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(24, 5, 5, 0.05);
  opacity: 1;
  z-index: 9999;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease-in-out;
  padding: 100px;
  transform: scale(1);
}

.cardImg {
  height: 403px;
  height: 400px;
}
.cardImg img {
  transform-origin: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.modal.activ {
  z-index: -9999;
  opacity: 0;
  transform: scale(0);
}
.modal .modal_body {
  /* transform: scale(0); */
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}
.modal.activ .modal_body {
  transform: scale(1);
}

.modal_grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.modal_box {
  position: relative;
}
.modal_body {
  width: 900px;
  width: 100%;
  grid-template-columns: 700px auto;
  background: var(--color4);
  position: relative;
  overflow: hidden;
}
.close {
  background: var(--color1);
  font-size: 35px;
  width: 60px;
  height: 60px;
  color: var(--color);
  position: absolute;
  top: 0;
  right: 0;
}
.close span {
  transform: rotate(45deg);
  display: block;
}

.modal_box {
  position: relative;
  padding: 20px;
  overflow: hidden;
}
.modal .modal_box {
  transform: translateX(0);
}
.modal_box h1 {
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
  margin-bottom: 30px;
}
.modal_box p {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15em;
  color: #989898;
  max-height: 600px;
  overflow-y: auto;
}
.modal_body {
  width: 700px;
  height: 700px;
  overflow: hidden;
  border: 1px solid var(--color1);
  position: relative;
}
.modal_body img {
  transform-origin: center;
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}
.modal_body::before {
  position: absolute;
  display: block;
  content: attr(data-key);
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
  background: var(--color);
  width: 100%;
  bottom: 0;
  padding: 5px;
  z-index: 1;
}
.zooms {
  overflow: hidden;
}
.moves {
  width: 100px;
  height: 100px;
  background: red;
  position: absolute;
  top: 0;
  left: 0;
}
.moves img {
  position: relative;
  z-index: -99;
}
.moves.activ {
  opacity: 1;
}
.cardUstara img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ustaraText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;
}
.ustarBox > h1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15em;
  color: var(--color3);
}

.ustarBox > p {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15em;
  color: var(--color3);
  word-break: break-all;
}
.ustaraText > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15em;
  color: #989898;
}

.cardUstara:hover {
  background: var(--color4);
  border: 0.1px solid var(--color1);
  /* transform: scale(1.05); */
  z-index: 9999;
}
.zoom_ {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 6.5px 5.32px 7px 7px;
  background: #d7b8a6;
  border-radius: 15px 0px 0px 15px;
  opacity: 0;
  transition: all var(--time) ease-in-out;
  transform: translateX(500px);
}
.cardUstara:hover .zoom_ {
  opacity: 1;
  transform: translateX(0px);
}
.maxsulot_btn {
  margin-left: 517px;
  margin-top: 36px;
}

.sliderItems {
  background: none;
}
.sliderPrev {
  transform: rotate(-180deg);
}
.sliderItems {
  margin: 0 36px;
}
.sliderHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ustaraCorusel .slick-dots {
  display: none !important;
}
.sliderScroll {
  display: flex;
  gap: 2px;
  align-items: center;
}
.sliderBox {
  width: 10px;
  height: 2px;
  display: block;
  background: var(--color3);
}
.sliderBox.start::after {
  display: block;
  content: attr(data-key);
  font-size: 22px;
  color: var(--color3);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #d7b8a6;
  transform: translate(-22px, -5px);
}
.sliderBox.end::after {
  display: block;
  content: attr(data-key);
  font-size: 22px;
  color: var(--color3);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #d7b8a6;
  transform: translate(14px, -8px);
}
.sliderBox.activ {
  height: 6px;
}
.sliderScroll p {
  font-size: 22px;
  color: var(--color3);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #d7b8a6;
}
.sliderHeader > h1 {
  font-weight: 300;
  font-size: 40px;
  line-height: 50px;
  text-align: right;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
  font-family: var(--famliy);
}
.firstCorus h1 {
  padding: 70px 0 70px;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
  text-align: left;
  font-family: var(--famliy);
}
.firstCorus {
  width: 50%;
}
.firstCorus > img {
  width: 406px;
}
.firstCorus p {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15em;
  color: #989898;
  word-break: break-all;
  /* width: 528px; */
}
.sliderCounters {
  margin-top: 160px;
  display: flex;
  align-items: center;
}
.yakkaCorus {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 70px;
}

.yakkaDoira {
  position: absolute;
  left: -690px;
  z-index: -1;
  top: -350px;
}
/* footer */

footer {
  background: var(--color);
}
.footer {
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
  align-items: center;
  justify-content: center;
  padding: 50px 0 35px;
  position: relative;
}
.footer::before {
  position: absolute;
  content: "";
  width: 100%;
  display: block;
  border-bottom: 1px solid var(--color1);
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.footerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 102px;
}
.logo-footer img {
  display: block;
  margin: auto;
}
.footer-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--color1);
  background: transparent;
  cursor: pointer;
}
.play {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 65px;
  padding-left: 2px;
  animation: animate-shadow 1.8s linear infinite;
}
.footer-link img {
  transform: rotate(180deg);
  padding: 15px;
  animation: top_animate_1 1s linear infinite alternate-reverse;
}
.animate-shadow:hover img {
  /* animation-play-state: paused; */
  animation: animate-shadow 1.8s linear infinite;
}
.modal_video {
  position: relative;
}
.modal_video video {
  width: 100%;
  height: 800px;
}
@keyframes animate-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(215, 184, 166, 0.7);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(2, 104, 104, 0);
  }

  80% {
    box-shadow: 0 0 0 50px rgba(2, 104, 104, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(2, 104, 104, 0);
  }
}
@keyframes top_animate_1 {
  0% {
    transform: rotate(180deg) translateY(10px);
  }
  100% {
    transform: rotate(180deg) translateY(0);
  }
}
.flexFooter {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.flexFooter a {
  color: var(--color1);
  font-weight: 600;
}
.flexFooter p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15em;
  color: var(--color3);
  padding: 18px 0 55px;
}
.footer a {
  white-space: nowrap;
}
.flexFooter p:nth-child(2) {
  text-align: right;
}
/* lang */
.detil {
  cursor: pointer;
  position: relative;
}
.detil li > button {
  background: transparent;
}
.list_lang {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  gap: 5px;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: -999;
}

.list_lang button.activ {
  background: var(--color2);
}
.list_lang.activ {
  opacity: 1;
  z-index: 999;
  top: 20px;
}
.list_lang {
  position: absolute;
  top: 0;
  left: 0;
  left: 22px;
}
.list_lang button {
  color: #141414;
  padding: 10px;
  font-size: 20px;
  position: absolute;
  transition: all 0.35s ease-in-out;
  transition-delay: 0.35s;
  opacity: 0;
  background: var(--color1);
  cursor: pointer;

}
.list_lang button {
  opacity: 1;
}
.list_lang button:nth-child(1) {
  /* transform:translateX(-60px) */
  animation: animateButtonClose 0.3s linear;
}
.list_lang.activ button:nth-child(1) {
  /* transform:translateX(-60px) */
  animation: animateButton 0.3s linear forwards 0.3s;
}

.list_lang.activ button:nth-child(3) {
  /* transform:translateX(-60px) */
  animation: animateButton2 0.3s linear forwards 0.3s;
}

.list_lang button:nth-child(3) {
  /* transform:translateX(-60px) */
  animation: animateButtonClose2 0.3s linear;
}
@keyframes animateButton {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-60px);
  }
}
@keyframes animateButtonClose {
  0% {
    transform: translateX(-60px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes animateButton2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(60px);
  }
}
@keyframes animateButtonClose2 {
  0% {
    transform: translateX(60px);
  }
  100% {
    transform: translateX(0);
  }
}
.grid__ {
  margin-top: 40px;
  display: grid;
  row-gap: 50px;
  grid-template-columns: repeat(4, calc(100% / 4));
}
/* medai */
@media (max-width: 1600px) {
  :root {
    --width: 1000px;
  }
  .pad {
    padding: 0 100px;
  }
  .contact .contactBox:first-child {
    padding: 120px 140px 140px 0;
  }
  .modal_grid {
    grid-template-columns: 600px auto;
    gap: 40px;
  }
  .modal {
    padding: 50px;
  }
  .modal_body {
    width: 600px;
    height: 600px;
  }
}
@media (max-width: 1400px) {
  .grid__ {
    grid-template-columns: repeat(3, calc(100% / 3));
  }
  .contact .contactBox:first-child {
    padding: 120px 60px 140px 0;
  }
  .modal_grid {
    padding: 40px;
  }
  .modal_grid {
    grid-template-columns: 500px auto;
    gap: 40px;
  }
  .modal {
    padding: 50px;
  }
  .modal_body {
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 1200px) {
  .contact .contactBox:first-child {
    padding: 40px;
  }
  .maxsulot_btn {
    margin-left: calc(50% - 300px);
  }
}

@media (max-width: 1200px) {
  .contact .contactBox:first-child {
    padding: 40px;
  }
  .maxsulot_btn {
    margin-left: calc(50% - 300px);
  }
  .modal_body::before {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 1100px) {
  .modal_grid {
    grid-template-columns: 400px auto;
    gap: 10px;
  }
  .modal {
    padding: 50px;
  }
  .modal_body {
    width: 400px;
    height: 400px;
  }
  .userNav {
    padding: 20px 0;
  }
  .contact {
    margin: 100px 0;
  }
  #maxsulotHaqida ~ br {
    display: none;
  }
  .ustaraCorusel {
    margin-bottom: 0;
  }
  .contact .contactBox:first-child {
    padding: 40px;
  }
  .maxsulot_btn {
    margin-left: calc(50% - 300px);
  }
  .pad {
    padding: 40px;
  }

  .footer {
    grid-template-columns: repeat(2, calc(100% / 2));
    justify-content: center;
    align-content: center;
  }
  .footerBox {
    margin: 40px 0;
  }
  .footer .footerBox:nth-child(3) {
    grid-column: span 2;
  }
  .grid3 {
    display: grid;
    grid-template-columns: repeat(2, calc(100% / 2));
    justify-content: center;
    align-content: center;
  }
  .contact_net {
    margin: 40px 0;
  }
  .grid .contact_net:nth-child(3) {
    grid-column: span 2;
  }
  .yakkaCorus .firstCorus:nth-child(1) img {
    width: 300px;
  }
  .contactUs {
    margin-top: 50px;
    padding-bottom: 117px;
  }
}
.threejs > div {
  position: absolute !important;
  top: 0;
}
@media (max-width: 991px) {
  .grid__ {
    grid-template-columns: repeat(2, calc(100% / 2));
  }
  .modal_body::before {
    display: none;
  }
  .modal_video video {
    height: 600px;
  }
  .zoom_ {
    opacity: 1;
    transform: translateX(0);
  }
  .contact .contactBox:first-child {
    padding: 40px;
  }
  .modal {
    padding: 20px;
  }
  .maxsulot_btn {
    margin-left: calc(50% - 300px);
  }
  .animate_header {
    height: 790px;
  }

  .animate_header .animate__:nth-child(2) img {
    width: 500px;
    height: 500px;
  }
  .threejs canvas {
    height: 860px !important;
  }

  .firstCorus p {
    width: auto;
  }
  .yakkaCorus {
    gap: 20px;
  }
  .footer {
    text-align: center;
    grid-template-columns: 100%;
  }
  .footer .footerBox:nth-child(3) {
    grid-column: span 1;
  }
  .firstCorus {
    width: 100%;
    text-align: center;
  }
  .yakkaCorus .firstCorus:nth-child(1) {
    text-align: left;
  }
  .firstCorus p {
    text-align: left;
  }
}

@media (max-width: 991px) {
  .grid3 {
    grid-template-columns: 100%;
  }
  .grid {
    grid-template-columns: 100%;
    grid-template-rows: 50% 500px;
  }
  .yakkaCorus {
    flex-direction: column;
  }
  .footerBox {
    display: block;
  }
  .footerBox > li {
    margin: 40px;
  }
  .pad {
    padding: 20px;
  }
  .ustaraCorusel > a button {
    margin-left: 10px;
  }
  .footerBox {
    margin: 20px 0;
  }
}

@media (max-width: 900px) {
  .headerUser > h1 {
    font-size: 20px;
    letter-spacing: 15px;
  }
}
@media (max-width: 776px) {
  .grid__ {
    grid-template-columns: 100%;
  }
  .modal_video video {
    height: 500px;
  }
  .headerUser > h1 {
    font-size: 18px;
    letter-spacing: 15px;
  }
  .modal_grid {
    grid-template-columns: 100%;
    gap: 10px;
    padding: 20px;
  }
  .modal_box {
    padding: 10px;
  }
  .modal {
    padding: 20px;
  }
  .modal_body {
    width: 100%;
    height: auto;
  }
  .modal_body img {
    display: block;
    margin: auto;
    transform-origin: center !important;
    transform: scale(1) !important  ;
  }
  .modal_box p {
    max-height: 250px;
  }
  .detil {
    gap: 20px;
  }
  .detil li > button {
    gap: 4px;
  }
  .userNav a {
    gap: 4px;
  }
  .firstCorus .slick-dots {
    margin-top: 80px;
  }
  .sliderHeader .sliderScroll {
    display: none;
  }
  .animate_header {
    height: 500px;
  }
  .animate_text {
    bottom: -64px;
  }
  .threejs {
    top: -135px;
  }
  .animate_header .animate__:nth-child(2) img {
    width: 350px;
    height: 350px;
  }
  .animate_header .animate__:nth-child(1) img {
    width: 400px;
    height: 400px;
  }
  .contactusItem > p {
    padding: 0 10px;
  }
}

@media (max-width: 556px) {
  .modal_video video {
    height: 400px;
  }
  .text_nav {
    display: none !important  ;
  }
  .flexCoruss ul {
    display: none;
  }
  .close {
    font-size: 25px;
    width: 40px;
    height: 40px;
  }
  .animate_text > h2 {
    font-size: 18px;
  }
  .animate_text > p {
    font-size: 18px;
    line-height: 20px;
  }
  .contact .contactBox > h1 {
    font-size: 22px;
  }
  .sliderHeader > h1 {
    font-size: 22px;
  }
  .btn_ {
    font-size: 16px;
  }
  .firstCorus h1 {
    font-size: 22px;
  }
  .firstCorus p {
    font-size: 16px;
  }
  .contact_net p {
    font-size: 22px;
  }
  .contact_net p:nth-child(2) {
    font-size: 18px;
  }
  .contactusItem > h1 {
    padding: 50px 0;
  }
  .contact_net {
    margin: 20px 0;
  }
  .contactusItem > p {
    font-size: 18px;
  }
  .footer {
    padding: unset;
  }
  .flexFooter {
    grid-template-columns: 100%;
    text-align: center !important;
  }
  .flexFooter p:nth-child(2) {
    text-align: center;
  }
  .flexFooter p {
    padding: 10px 0;
  }
}

@media (max-width: 456px) {
  .modal_video video {
    height: 350px;
  }
  .threejs canvas {
    height: 580px !important;
  }
  .headerUser > h1 {
    padding: 0 !important;
  }
  .animate_text {
    bottom: -14px;
  }
  .grid {
    grid-template-rows: 50% 500px;
  }
  .contact .contactBox:first-child {
    padding: 20px;
  }
  .headerUser > h1 {
    padding: 20px;
    letter-spacing: 5px;
  }
  .sliderItems {
    margin: 0 10px;
  }
  .btn_ {
    padding: 10px 12px;
  }
  .firstCorus > img {
    width: 100%;
  }
  /* .logo img{
      width: calc(111px / 2);
      height: 25px;
    } */
  .text_nav {
    display: none !important;
  }
  .userNav {
    gap: 40px;
  }
  .list_lang {
    left: 0;
  }
  .detil {
    gap: 40px;
  }
  .pad {
    padding: 0 20px !important;
  }
  .modal_grid {
    padding: 5px;
  }
}
@media (max-width: 400px) {
  .modal_video video {
    height: 300px;
  }
}
@media (max-width: 350px) {
  .modal_video video {
    height: 280px;
  }
}


